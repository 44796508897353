module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N3RHBKQ","includeInDevelopment":true,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SSLLC: Used lab equipment ","short_name":"SSLLC","start_url":"/","background_color":"#FFFFFF","theme_color":"#166db6","display":"minimal-ui","icon":"src/images/logo-large.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"44bea888221e59c0899981a3449565fb"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
